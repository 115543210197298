import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { TruncateWithDotsPipe } from './truncate-with-dots.pipe';

@NgModule({
    declarations: [TruncateWithDotsPipe],
    // imports: [CommonModule],
    exports: [TruncateWithDotsPipe]
})
export class CustomPipesModule { }
